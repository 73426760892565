var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":function($event){return _vm.$emit('deviceClick')}}},[_c('el-card',{attrs:{"body-style":"position: relative","shadow":"0"}},[_c('div',{staticClass:"device__author"},[_vm._v(" Лампочка ("+_vm._s(_vm.device.author)+") ")]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-4 col-lg-3 col-md-3 col-sm-4 p-0"},[_c('button',{staticClass:"device__btn",class:{on_shadow: !_vm.color && _vm.onOff === 'on'},style:({
            boxShadow: _vm.onOff === 'on' ?
              _vm.color ?
              ("6px 6px 10px -1px " + _vm.color + ",  -6px -6px 10px -1px " + _vm.color)
              :
              '6px 6px 10px -1px rgba(255,234,0,0.2),  -6px -6px 10px -1px rgba(255, 234, 0, 0.4)'
              : ''
          }),attrs:{"disabled":!_vm.onOff},on:{"click":function($event){$event.stopPropagation();return _vm.toggleOnOff($event)}}},[_c('span',{staticClass:"icon-bulb",staticStyle:{"font-size":"32px"},style:({ color: _vm.onOff === 'on' ? _vm.color : 'inherit' || '#FFEA00' })})])]),_c('div',{staticClass:"col-8 col-lg-9 col-md-9 col-sm-8 text-left text-sm-left text-md-center"},[_vm._v(" "+_vm._s(_vm.device.name)+" "),(_vm.brightness)?_c('div',[_vm._v(" яркость: "+_vm._s(_vm.brightness)+"% ")]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }