<template>
  <div @click="$emit('deviceClick')">
    <el-card
      body-style="position: relative"
      shadow="0"
      >
      <div class="device__author">
        Лампочка ({{device.author}})
      </div>
      <div class="row align-items-center">
        <div class="col-4 col-lg-3 col-md-3 col-sm-4 p-0">
          <button
            :class="{on_shadow: !color && onOff === 'on'}"
            :style="{
              boxShadow: onOff === 'on' ?
                color ?
                `6px 6px 10px -1px ${color},  -6px -6px 10px -1px ${color}`
                :
                '6px 6px 10px -1px rgba(255,234,0,0.2),  -6px -6px 10px -1px rgba(255, 234, 0, 0.4)'
                : ''
            }"
            :disabled="!onOff"
            @click.stop="toggleOnOff"
            class="device__btn"
          >
            <!--
            <font-awesome-icon
              icon="lightbulb" size="2x" :color="color"
            />
            -->
            <span
              class="icon-bulb"
              style="font-size: 32px"
              :style="{ color: onOff === 'on' ? color : 'inherit' || '#FFEA00' }"
            ></span>
          </button>
        </div>
        <div class="col-8 col-lg-9 col-md-9 col-sm-8 text-left text-sm-left text-md-center">
          {{device.name}}
          <!--
            <a v-if="onOff">
              {{onOff === 'on' ? '(включена)' : '(выключена)'}}
            </a>
          -->
          <div v-if="brightness">
            яркость: {{brightness}}%
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  /**
   * Компонент для отображения устройства типа light (лампочка)
   */
  name: 'Light',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      /**
       * Свойство цвета
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.rgb;
      }
      return '';
    },
    onOff() {
      /**
       * Свойство вкл/выкл
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities['on-off'];
      }
      return false;
    },
    brightness() {
      /**
       * Свойство яркость
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.brightness;
      }
      return false;
    },
  },
  methods: {
    toggleOnOff() {
      /**
       * Функция обработки изменения свойства вкл/выкл
       */
      if (this.device && this.device.id && this.device.possibilities['on-off']) {
        this.$store.dispatch('changePossibilityValue', {
          deviceId: this.device.id,
          possibilityKey: 'on-off',
          newValue: this.device.possibilities['on-off'] === 'on' ? 'off' : 'on',
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-card:hover{
  border: solid 1px rgba(64, 158, 255, 0.3);
  cursor: pointer;
}

.el-card {
  background: #3c4b73;
  border: solid 1px #4d5878;
  border-radius: 10px;
  color: white;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.on_shadow {
  box-shadow: 6px 6px 10px -1px rgba(255,234,0,0.2),  -6px -6px 10px -1px rgba(255, 234, 0, 0.4);
}

</style>
